import { fetchAPI } from '@/djangoAPI';

export const getAllStore = async ({ commit }) => {
  await fetchAPI.get('/resources/store/?limit=1000')
    .then((response) => {
      commit('SET_Store', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllChannel = async ({ commit }) => {
  await fetchAPI.get('/purchase/channel/?limit=1000')
    .then((response) => {
      commit('SET_Channel', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllSegment = async ({ commit }) => {
  await fetchAPI.get('/technical/segment/?limit=1000')
    .then((response) => {
      commit('SET_Segment', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllBrand = async ({ commit }) => {
  await fetchAPI.get('/resources/brand/?limit=1000')
    .then((response) => {
      commit('SET_Brand', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllEventRules = async ({ commit }) => {
  await fetchAPI.get('/engine/event-rule/?limit=1000')
    .then((response) => {
      commit('SET_EventRules', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllClientData = async ({ commit }) => {
  await fetchAPI.get('/technical/client-data/?limit=1000')
    .then((response) => {
      commit('SET_ClientData', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllTechnicalData = async ({ commit }) => {
  await fetchAPI.get('/technical/technical-data/?limit=1000')
    .then((response) => {
      commit('SET_technicalData', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllTechnicalDataType = async ({ commit }) => {
  await fetchAPI.get('/technical/technical-data-type/?limit=1000')
    .then((response) => {
      commit('SET_technicalDataType', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getEngine = async ({ commit }) => {
  await fetchAPI.get('/engine/engine/1/?limit=1000')
    .then((response) => {
      commit('SET_engine', response);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllExtraInfo = async ({ commit }) => {
  await fetchAPI.get('/technical/extra-info/?limit=1000')
    .then((response) => {
      commit('SET_extraInfo', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getAllConstraint = async ({ commit }) => {
  await fetchAPI.get('/technical/constraint/?limit=1000')
    .then((response) => {
      commit('SET_constraint', response.results);
    })
    .catch((error) => {
      console.log(error);
    });
};
