<template>
  <div>
    <Notification v-if="store.state.notificationDisplay" />

    <template v-if="store.state.session.userAccessToken && !route.query.canvas">
      <!-- sidebar for mobile -->
      <TransitionRoot
        :show="sidebarOpen"
        as="template"
      >
        <Dialog
          as="div"
          class="relative z-40 md:hidden"
          @close="sidebarOpen = false"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>

          <div class="fixed inset-0 flex z-40">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      type="button"
                      @click="sidebarOpen = false"
                    >
                      <span class="sr-only">Close sidebar</span>
                      <XIcon
                        aria-hidden="true"
                        class="h-4 w-4 text-white"
                      />
                    </button>
                  </div>
                </TransitionChild>

                <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div>
                    <a
                      v-if="!store.state.session.inProgress"
                      class="flex items-center flex-shrink-0 px-4"
                      target="_blank"
                      :href="(!store.state.engine.url || store.state.engine.url.length === 0) ? 'https://www.lineup7.fr/': store.state.engine.url"
                    >
                      <img
                        :src="(!store.state.engine.logo || store.state.engine.logo.length === 0 || store.state.engine.logo.includes('media/\'\'')) ? store.state.config.clientLogoWhite : store.state.engine.logo"
                        alt="Logo de l'entreprise"
                        class="max-w-44 max-h-44"
                      >
                    </a>
                  </div>

                  <nav class="mt-5 px-2 space-y-1">
                    <div
                      v-for="item in navigation.routes"
                      :key="item.name"
                      :class="[item.routeName === $route.name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']"
                      @click="updateOpenValue(route)"
                    >
                      <router-link
                        v-if="item.children.length === 0"
                        :to="{ name: item.routeName}"
                        @click="sidebarOpen = false"
                      >
                        <font-awesome-icon
                          :icon="item.icon"
                          :class="[item.routeName === $route.name ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-4 flex-shrink-0 h-4 w-4']"
                          aria-hidden="true"
                        />
                        {{ item.name }}
                      </router-link>
                      <Disclosure
                        v-else
                        v-slot="{ open }"
                        :open="item.open"
                        as="div"
                        class="space-y-1"
                        @click="updateOpenValue(route)"
                      >
                        <DisclosureButton
                          :class="[item.routeName === $route.name ? 'bg-gray-100 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500']"
                        >
                          <font-awesome-icon
                            :icon="item.icon"
                            :class="[item.routeName === $route.name ? 'text-white' : 'text-gray-400 group-hover:text-gray-300', 'mr-4 flex-shrink-0 h-4 w-4']"
                            aria-hidden="true"
                          />
                          <span class="flex-1">
                            {{ item.name }}
                          </span>

                          <svg
                            :class="[open ? 'text-gray-400 rotate-90' : 'text-gray-300', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150']"
                            aria-hidden="true"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M6 6L14 10L6 14V6Z"
                              fill="currentColor"
                            />
                          </svg>
                        </DisclosureButton>

                        <DisclosurePanel
                          v-if="item.children"
                          class="space-y-1"
                        >
                          <button
                            v-for="subRoute in item.children"
                            :key="subRoute.name"
                            as="a"
                            class="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                            @click="$router.push({ name: subRoute.name}); sidebarOpen = false"
                          >
                            <font-awesome-icon
                              :icon="subRoute.meta.headerInfos.icon"
                              :class="[subRoute.name === $route.name ? 'text-white' : 'text-gray-400 group-hover:text-white', 'mr-4 flex-shrink-0 h-4 w-4']"
                              aria-hidden="true"
                            />
                            {{ subRoute.meta.headerInfos.title }}
                          </button>
                        </DisclosurePanel>
                      </Disclosure>
                    </div>
                  </nav>

                  <div class="space-y-4">
                    <h3
                      id="projects-headline"
                      class="px-3 text-sm font-medium text-gray-500"
                    />
                    <div
                      aria-labelledby="projects-headline"
                      class="space-y-1"
                      role="group"
                    >
                      <div
                        v-for="row in usefulLinks"
                        :key="row.name"
                        class="cursor-pointer"
                        :href="row.href"
                      >
                        <a
                          v-if="row.externalLink"
                          :href="row.href"
                          target="_blank"
                          class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <span class="truncate">
                            {{ row.name }}
                          </span>
                        </a>
                        <router-link
                          v-else
                          :to="row.href"
                          class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                          @click="sidebarOpen = false"
                        >
                          <span class="truncate">
                            {{ row.name }}
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-shrink-0 flex bg-gray-700 p-4">
                  <a
                    class="flex-shrink-0 group block"
                    href="#"
                  >
                    <div class="flex items-center">
                      <div>
                        <span class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                          <span class="font-medium leading-none text-white">
                            {{ `${store.state.session.firstName.charAt(0)?.toUpperCase()}${store.state.session.lastName.charAt(0)?.toUpperCase()}` }}
                          </span>
                        </span>
                      </div>
                      <div
                        class="ml-3 flex justify-between items-center space-x-5"
                        @click="UserSession.logoutSession()"
                      >
                        <p class="text-base font-medium text-white">{{ [store.state.session.firstName, store.state.session.lastName].join(" ") }}</p>
                        <button>
                          <font-awesome-icon
                            class="text-white "
                            icon="fa-solid fa-right-from-bracket"
                          />
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
              </DialogPanel>
            </TransitionChild>
            <div class="flex-shrink-0 w-14">
              <!-- Force sidebar to shrink to fit close icon -->
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div>
              <a
                v-if="!store.state.session.inProgress"
                class="flex items-center flex-shrink-0 px-4"
                target="_blank"
                :href="(!store.state.engine.url || store.state.engine.url.length === 0) ? 'https://www.lineup7.fr/': store.state.engine.url"
              >
                <img
                  :src="(!store.state.engine.logo || store.state.engine.logo.length === 0 || store.state.engine.logo.includes('media/\'\'')) ? store.state.config.clientLogoWhite : store.state.engine.logo"
                  alt="Logo de l'entreprise"
                  class="max-w-44 max-h-44"
                >
              </a>
            </div>

            <div class="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
              <Menu
                as="div"
                class="px-3 relative inline-block text-left"
              >
                <div>
                  <MenuButton class="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                    <span class="flex w-full justify-between items-center">
                      <span class="flex min-w-0 items-center justify-between space-x-3">
                        <span class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                          <span class="font-medium leading-none text-white">
                            {{ `${store.state.session.firstName.charAt(0)?.toUpperCase()}${store.state.session.lastName.charAt(0)?.toUpperCase()}` }}
                          </span>
                        </span>

                        <span class="flex-1 flex flex-col min-w-0">
                          <span class="text-gray-900 text-sm font-medium truncate">{{ [store.state.session.firstName, store.state.session.lastName].join(" ") }}</span>

                          <span class="text-gray-500 text-sm truncate">{{ store.state.session.permission.title }}</span>
                        </span>
                      </span>

                      <SelectorIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems class="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a
                          :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']"
                          @click="UserSession.logoutSession()"
                        >
                          <div class="mx-2 flex justify-between items-center">
                            <span>Se déconnecter</span>
                            <font-awesome-icon
                              class="text-gray-900"
                              icon="fa-solid fa-right-from-bracket"
                            />
                          </div>
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>

              <div class="mt-5 flex-grow flex flex-col">
                <nav
                  aria-label="Sidebar"
                  class="flex-1 px-2 space-y-8 bg-gray"
                >
                  <div class="mt-5 flex-1 px-2 space-y-1">
                    <template
                      v-for="route in navigation.routes"
                      :key="route.name"
                    >
                      <router-link
                        v-if="route.children.length === 0"
                        :class="[route.routeName === $route.name ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']"
                        :to="{ name: route.routeName}"
                        @click="updateOpenValue(route)"
                      >
                        <font-awesome-icon
                          :icon="route.icon"
                          :class="[route.routeName === $route.name ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-4 flex-shrink-0 h-4 w-4']"
                          aria-hidden="true"
                        />
                        {{ route.name }}
                      </router-link>

                      <Disclosure
                        v-else
                        v-slot="{ open }"
                        :open="route.open"
                        as="div"
                        class="space-y-1"
                        @click="updateOpenValue(route)"
                      >
                        <DisclosureButton
                          :class="[route.routeName === $route.name ? 'bg-gray-100 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500']"
                        >
                          <font-awesome-icon
                            :icon="route.icon"
                            :class="[route.routeName === $route.name ? 'text-white' : 'text-gray-400 group-hover:text-gray-300', 'mr-4 flex-shrink-0 h-4 w-4']"
                            aria-hidden="true"
                          />
                          <span class="flex-1">
                            {{ route.name }}
                          </span>

                          <svg
                            :class="[open ? 'text-gray-400 rotate-90' : 'text-gray-300', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150']"
                            aria-hidden="true"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M6 6L14 10L6 14V6Z"
                              fill="currentColor"
                            />
                          </svg>
                        </DisclosureButton>

                        <DisclosurePanel
                          v-if="route.children"
                          class="space-y-1"
                        >
                          <button
                            v-for="subRoute in route.children"
                            :key="subRoute.name"
                            as="a"
                            class="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-300 rounded-md hover:text-white hover:bg-gray-700"
                            @click="$router.push({ name: subRoute.name})"
                          >
                            <font-awesome-icon
                              :icon="subRoute.meta.headerInfos.icon"
                              :class="[subRoute.name === $route.name ? 'text-white' : 'text-gray-400 group-hover:text-white', 'mr-4 flex-shrink-0 h-4 w-4']"
                              aria-hidden="true"
                            />
                            {{ subRoute.meta.headerInfos.title }}
                          </button>
                        </DisclosurePanel>
                      </Disclosure>
                    </template>
                  </div>

                  <div class="space-y-1">
                    <h3
                      id="projects-headline"
                      class="px-3 text-sm font-medium text-gray-500"
                    />

                    <div
                      aria-labelledby="projects-headline"
                      class="space-y-1"
                      role="group"
                    >
                      <div
                        v-for="row in usefulLinks"
                        :key="row.name"
                        class="cursor-pointer"
                        :href="row.href"
                      >
                        <a
                          v-if="row.externalLink"
                          :href="row.href"
                          target="_blank"
                          class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <span class="truncate">
                            {{ row.name }}
                          </span>
                        </a>
                        <router-link
                          v-else
                          :to="row.href"
                          class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                        >
                          <span class="truncate">
                            {{ row.name }}
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="md:pl-64 flex flex-col flex-1">
        <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            type="button"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuIcon
              aria-hidden="true"
              class="h-4 w-4"
            />
          </button>
        </div>

        <main
          v-if="!store.state.session.inProgress"
          class="flex-1"
        >
          <div class="py-6">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <Breadcrumbs />
              <router-view />
            </div>
          </div>
        </main>
      </div>
    </template>

    <template v-else>
      <div class="p-6">
        <router-view />
      </div>
    </template>
  </div>

  <!-- MODAL LOGIN LOADING -->
  <TransitionRoot
    as="template"
    :show="store.state.session.inProgress"
  >
    <Dialog
      as="div"
      class="relative z-10"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                  <font-awesome-icon
                    icon="fa-solid fa-spinner"
                    class="fa-spin"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    Chargement en cours..
                  </DialogTitle>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- END MODAL LOGIN LOADING -->
</template>

<script setup>
import {
  computed, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import {
  Menu,
  MenuItem,
  MenuItems,
  MenuButton,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  DialogTitle,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';

import {
  SelectorIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';

import { useRouter, useRoute } from 'vue-router';
import { UserSession } from '@/users';
import Notification from '@/components/Modal/Notification';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';

const store = useStore();
const router = useRouter();
const route = useRoute();

const usefulLinks = computed(() => {
  const table = [{ name: 'Swagger-UI', href: process.env.VUE_APP_AXIOS_BASEURL_DJANGOAPI.replace('api', 'swagger'), externalLink: true }];
  if (store.state.session.isAdmin === true) {
    table.push({ name: 'Admin', href: '/admin', externalLink: false });
  }
  return table;
});

const navigation = reactive({
  isLoading: false,
  routes: [],
});

const sidebarOpen = ref(false);
// closes the notification after a certain time
const timeoutID = ref(null);

function updateOpenValue(route) {
  route.open = !route.open;
}

onBeforeMount(() => {
  // Init user session
  if (!route.query.canvas) {
    UserSession.initSession();

    // Init header navigation
    router.options.routes.forEach((route) => {
      if (route.meta.hasOwnProperty('headerInfos') || route.meta.hasOwnProperty('childrenMain')) {
        if (route.meta.hasOwnProperty('childrenMain')) {
          const r = route.children.find((r) => r.name === route.meta.childrenMain);
          navigation.routes.push({
            name: r.meta.headerInfos.title,
            routeName: r.name,
            href: r.path,
            icon: r.meta.headerInfos.icon,
            children: r.hasOwnProperty('children') ? r.children : [],
            open: false,
          });
        } else if (route.name !== 'Admin') {
          navigation.routes.push({
            name: route.meta.headerInfos.title,
            routeName: route.name,
            href: route.path,
            icon: route.meta.headerInfos.icon,
            children: route.hasOwnProperty('children') ? route.children : [],
            open: false,
          });
        }
      }
    });
    navigation.isLoading = true;
  }
});
watch(store.state, () => {
  if (store.state.notificationDisplay) {
    timeoutID.value = setTimeout(() => {
      store.state.notificationDisplay = false;
    }, 6000);
  } else {
    clearTimeout(timeoutID.value);
  }
});

</script>
