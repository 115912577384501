export const Store = (state) => state.store;
export const Channel = (state) => state.channel;
export const Segment = (state) => state.segment;
export const EventRules = (state) => state.eventRules;
export const Brand = (state) => state.brand;
export const ClientData = (state) => state.clientData;
export const TechnicalData = (state) => state.technicalData;
export const TechnicalDataType = (state) => state.technicalDataType;
export const NotificationDisplay = (state) => state.notificationDisplay;
export const NotificationParams = (state) => state.notificationParams;
export const Engine = (state) => state.engine;
export const ExtraInfo = (state) => state.extraInfo;
export const Constraint = (state) => state.constraint;
