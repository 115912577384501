<template>
  <nav
    v-if="route.meta.breadcrumb"
    class="flex mb-3 ml-3"
    aria-label="Breadcrumb"
  >
    <ol
      role="list"
      class="flex items-center space-x-4"
    >
      <li>
        <button
          class="text-gray-700 hover:text-gray-500"
          @click="router.push({ name: 'Home' })"
        >
          <font-awesome-icon
            class="h-5 w-5 flex-shrink-0 px-2"
            icon="fa-solid fa-house"
          />
          <span class="sr-only">Home</span>
        </button>
      </li>
      <li
        v-for="(item, idx) in breadcrumb"
        :key="idx"
      >
        <div class="flex items-center">
          <font-awesome-icon
            class="h-4 w-4 flex-shrink-0 text-gray-500"
            icon="fa-solid fa-chevron-right"
          />
          <button
            :class="item.redirectTo === route.name ? 'font-bold text-gray-800' : 'font-medium text-gray-500'"
            class="ml-4 text-sm hover:text-gray-700"
            @click="router.push({ name: item.redirectTo })"
          >
            {{ item.name }}
          </button>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter();

const breadcrumb = ref([]);

function setData() {
  if (route.meta.breadcrumb) {
    if (typeof route.meta.breadcrumb === 'function') {
      breadcrumb.value = route.meta.breadcrumb(route);
    } else {
      breadcrumb.value = route.meta.breadcrumb;
    }
  } else {
    breadcrumb.value = [];
  }
}

onBeforeMount(() => {
  setData();
});

watch(route, () => {
  setData();
});

</script>
