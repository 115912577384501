import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import './index.css';

import { Cookies } from '@/cookies';

import FontAwesomeIcon from '@/fontawesome';

// Create Vue APP
const app = createApp(App);

app.use(store);
// Add router to Vue APP
app.use(router);
// Add FontAwesomeIcon to Vue APP
app.component('FontAwesomeIcon', FontAwesomeIcon);
// Define djangoAPI instance
app.config.globalProperties.$cookies = Cookies;
// Mount Vue APP
app.mount('#app');
