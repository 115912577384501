<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    v-if="store.state.notificationParams.title"
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <div v-if="store.state.notificationParams.type === 'success'">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="h-6 w-6 text-green-400"
                  />
                </div>
                <div v-else-if="store.state.notificationParams.type === 'warning'">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-xmark"
                    class="h-6 w-6 text-red-600"
                  />
                </div>
                <div v-else-if="store.state.notificationParams.type === 'info'">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-info"
                    class="h-6 w-6 text-gray-700"
                  />
                </div>
                <!-- <CheckCircleIcon v-else class="h-6 w-6 text-red-400" aria-hidden="true" />-->
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ store.state.notificationParams.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{ store.state.notificationParams.description }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="store.state.notificationDisplay = false"
                >
                  <span class="sr-only">Fermer</span>
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-xmark"
                      class="h-4 w-4 text-gray-500 hover:text-gray-900"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();

</script>
