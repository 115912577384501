// eslint-disable-next-line import/prefer-default-export
export const Cookies = {
  get: (name) => {
    /**
     *
     * @name {string} Cookie name
     */
    name += '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  set: (name, value, expireIn) => {
    /**
     *
     * @name {string} Cookie name
     * @value {string} Cookie definition
     * @expireIn {string} Cookie expire
     *
     */
    const d = new Date();
    d.setTime(d.getTime() + (expireIn * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  },
  delete: (name) => {
    document.cookie = `${name}=`
    + ';path=/'
    + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  },
};
