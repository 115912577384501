import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const headerDefaultInfos = {
  show: true,
  title: 'Pas de nom',
  icon: '',
};

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      sessionRequired: true,
      headerInfos: {
        ...headerDefaultInfos,
        ...{ title: 'Accueil', icon: 'fa-solid fa-house' },
      },
    },
  },
  // Authentification
  {
    path: '/authentification',
    name: 'Authentification',
    component: () => import('../views/Authentification/SignIn/SignIn'),
    meta: {
      sessionRequired: false,
    },
  },
  {
    name: 'FidAccountHome',
    component: () => import('@/views/Resources/FidAccount/Home'),
    meta: {
      sessionRequired: true,
      childrenMain: 'FidAccountSearch',
    },
    children: [
      {
        path: '/contacts',
        name: 'FidAccountSearch',
        component: () => import('@/views/Resources/FidAccount/FidAccountSearch'),
        meta: {
          breadcrumb: [{ name: 'Contacts', redirectTo: 'FidAccountSearch' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Comptes fidélité', icon: 'fa-solid fa-users' },
          },
        },
      },
      {
        path: '/contacts/:id',
        name: 'FidAccountDetail',
        component: () => import('@/views/Resources/FidAccount/View/FidAccountView'),
        meta: {
          breadcrumb(route) {
            return [
              { name: 'Contact', redirectTo: 'FidAccountSearch' },
              {
                name: `Détail du contact n°${route.params.id}`,
                redirectTo: 'FidAccountDetail',
              },
            ];
          },
          sessionRequired: true,
        },
      },
    ],
  },
  {
    path: '/resources',
    name: 'Resources',
    meta: {
      sessionRequired: true,
      description: 'Référentiels',
      headerInfos: {
        ...headerDefaultInfos,
        ...{ title: 'Référentiels', icon: 'fa-solid fa-list-tree' },
      },
    },
    component: () => import('../views/Resources/Resources'),
    children: [
      {
        path: 'store',
        name: 'Store',
        component: () => import('@/views/Resources/Store/Store'),
        meta: {
          breadcrumb: [{ name: 'Magasins', redirectTo: 'Store' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Magasins', icon: 'fa-solid fa-store' },
          },
        },
      },
      {
        path: 'product',
        name: 'Product',
        component: () => import('@/views/Resources/Product/Product'),
        meta: {
          breadcrumb: [{ name: 'Produits', redirectTo: 'Product' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Produits', icon: 'fa-solid fa-box' },
          },
        },
      },
    ],
  },
  {
    path: '/engine',
    name: 'Engine',
    meta: {
      sessionRequired: true,
      description: 'engine',
      headerInfos: {
        ...headerDefaultInfos,
        ...{ title: 'Configuration', icon: 'fa-solid fa-gear' },
      },
    },
    component: () => import('../views/Resources/Resources'),
    children: [
      {
        path: 'earn-rule',
        name: 'Earn-rule',
        component: () => import('@/views/Engine/EarnRules/EarnRules'),
        meta: {
          breadcrumb: [{ name: 'Règles de earn', redirectTo: 'Earn-rule' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Règles de earn', icon: 'fa-solid fa-coins' },
          },
        },
      },
      {
        path: 'burn-rule',
        name: 'Burn-rule',
        component: () => import('@/views/Engine/BurnRules/BurnRules'),
        meta: {
          breadcrumb: [{ name: 'Règles de burn', redirectTo: 'Burn-rule' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Règles de burn', icon: 'fa-solid fa-fire' },
          },
        },
      },
      {
        path: 'event-rule',
        name: 'Event-rule',
        component: () => import('@/views/Engine/EventRules/EventRules'),
        meta: {
          breadcrumb: [{ name: "Règles d'événement", redirectTo: 'Event-rule' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{
              title: "Règles d'événement",
              icon: 'fa-solid fa-list-dropdown',
            },
          },
        },
      },
      {
        path: 'booster',
        name: 'Booster',
        component: () => import('@/views/Engine/Booster/Booster'),
        meta: {
          breadcrumb: [{ name: 'Booster', redirectTo: 'Booster' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Booster', icon: 'fa-solid fa-up-right' },
          },
        },
      },
      {
        path: 'gift',
        name: 'Gift',
        component: () => import('@/views/Loyalty/Gift/Gift'),
        meta: {
          breadcrumb: [{ name: 'Cadeaux', redirectTo: 'Gift' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Cadeaux', icon: 'fa-solid fa-gifts' },
          },
        },
      },
    ],
  },
  {
    path: '/parameters',
    name: 'parameters',
    meta: {
      sessionRequired: true,
      description: 'Paramétrages',
      headerInfos: {
        ...headerDefaultInfos,
        ...{ title: 'Paramétrages', icon: 'fa-solid fa-option' },
      },
    },
    component: () => import('../views/Resources/Resources'),
    children: [
      {
        path: '/users',
        name: 'Users',
        meta: {
          breadcrumb: [{ name: 'Utilisateurs', redirectTo: 'Users' }],
          sessionRequired: true,
          description: 'Liste des utilisateurs',
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Utilisateurs', icon: 'fa-solid fa-users-gear' },
          },
        },
        component: () => import('../views/Users/Users'),
      },
      {
        path: 'constraint',
        name: 'Constraint',
        component: () => import('@/views/Technical/Constraints/Constraints'),
        meta: {
          breadcrumb: [{ name: 'Contraintes', redirectTo: 'Constraint' }],
          sessionRequired: true,
          headerInfos: {
            ...headerDefaultInfos,
            ...{ title: 'Contraintes', icon: 'fa-solid fa-gears' },
          },
        },
      },
    ],
  },
  {
    path: '/fraude',
    name: 'Fraude',
    component: () => import('@/views/Analytics/Analytics'),
    meta: {
      breadcrumb: [{ name: 'Fraude', redirectTo: 'Fraude' }],
      sessionRequired: true,
      headerInfos: {
        ...headerDefaultInfos,
        ...{
          title: 'Gestion des fraudes',
          icon: 'fa-solid fa-solid fa-chart-line',
        },
      },
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Configuration/Admin/Admin'),
    meta: {
      breadcrumb: [{ name: 'Admin', redirectTo: 'Admin' }],
      sessionRequired: true,
      headerInfos: {
        ...headerDefaultInfos,
        ...{
          title: 'Administration',
          icon: 'fa-solid fa-solid fa-user',
        },
      },
    },
  },
  {
    path: '/embed/contacts',
    name: 'Embed',
    component: () => import('@/views/Embed/IframeContact'),
    meta: {
      canvas: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.canvas) {
    next();
  } else if (to.meta.sessionRequired) {
    if (store.state.session.userAccessToken) {
      next();
    } else {
      next({
        path: '/authentification',
        query: {
          query: to.path,
        },
      });
    }
  } else if (store.state.session.userAccessToken) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
